import React from 'react';
import styles from './renderers.module.css';

export const Media = ({ src, alt, ...props }) => {
  const fileTypeRegex = /fileType:([^\s]+)/;
  const match = alt.match(fileTypeRegex);
  const fileType = match ? match[1].toLowerCase() : 'image';

  // ![alt fileType:audio](url)
  if (fileType === 'audio')
    return (
      <audio controls className={styles.media}>
        <source src={src} alt={alt} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
    );

  // ![alt](url) or ![alt fileType:image](url)
  return <img src={src} alt={alt} className={styles.media} />;
};
