import React from 'react';

import {
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
} from '@chakra-ui/react';

export const TextField = ({
  label,
  placeholder,
  type,
  helperText,
  ...props
}) => {
  return (
    <FormControl>
      <FormLabel>{label}</FormLabel>
      <Input type={type} placeholder={placeholder} {...props} />
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};
