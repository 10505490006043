import React, { forwardRef, useImperativeHandle } from 'react';
import { Box, Image, Text, Icon } from '@chakra-ui/react';
import { MdInfoOutline } from 'react-icons/md';
import { MessageWrapper } from './MessageWrapper';

import { useAnswerAnimation } from 'hooks';

import { LogoIcon } from 'assets';
import ReactMarkdown from 'react-markdown';

import remarkGfm from 'remark-gfm';
import remarkBreaks from 'remark-breaks';
import renderers from 'components/Renderers';
import { buildPublicUrl } from 'services';

import styles from '../Chat.module.css';

export const Answer = forwardRef(
  (
    {
      children,
      type,
      sources,
      onFinish,
      avatar,
      avatarUrl,
      typingAnimation,
      isTruncated,
      includeMessageSources,
      reference,
      maxImageWidth,
    },
    ref
  ) => {
    const { typedContent, stopAnimation: stopAnimationHandler } =
      useAnswerAnimation({
        content: children,
        onFinish,
        sources,
        typingAnimation,
      });

    const avatarSrc = (() => {
      if (avatar) return buildPublicUrl(avatar);
      else if (avatarUrl) return avatarUrl;
      else return null;
    })();

    useImperativeHandle(ref, () => ({
      stopAnimation() {
        stopAnimationHandler();
      },
    }));

    return (
      <MessageWrapper justifyContent="flex-start">
        <Box
          mt="4px"
          w="30px"
          h="30px"
          alignSelf="baseline"
          borderRadius="50%"
          overflow="hidden"
          border="1px solid"
          borderColor="#e2e8f0"
          flexShrink={0}
          className={styles.logoContainer}
          display={'flex'}
          padding={avatarSrc ? '0px' : '6px'}
        >
          {avatarSrc ? (
            <Image src={avatarSrc} alt="Avatar" objectFit="cover" />
          ) : (
            <LogoIcon />
          )}
        </Box>

        <Box maxWidth="95%">
          <Box
            overflowX="auto"
            border="1px solid"
            borderColor={type === 'error' ? 'red.500' : '#dfe3ee'}
            bgColor="#f7f9ff"
            p="8px 12px"
            borderRadius="14px"
            borderTopLeftRadius={0}
            boxShadow="0 1px 8px 0 rgba(72, 79, 101, 0.18)"
            style={{ '--maxImageWidth': maxImageWidth || '100%' }}
          >
            <ReactMarkdown
              remarkPlugins={[remarkGfm, remarkBreaks]}
              className={styles.reactMarkDown}
              components={renderers}
              linkTarget="_blank"
            >
              {typedContent}
            </ReactMarkdown>
          </Box>
          {includeMessageSources && reference && (
            <Text ml="8px" fontSize="12px" mt="4px" color="#484f79">
              <b>Sources:</b> {reference}
            </Text>
          )}
          {isTruncated && (
            <Box display="flex" alignItems="center" gap={1} marginTop={2}>
              <Icon as={MdInfoOutline} boxSize={4} color="#848891" />
              <Text fontSize="xs" color="#848891">
                The response could have been limited to 500 records to optimize
                performance.
              </Text>
            </Box>
          )}
        </Box>
      </MessageWrapper>
    );
  }
);
