import { useRef, useEffect } from "react";

export const useTimer = ({ time, onFinish }) => {
    const timerRef = useRef(null);

    useEffect(() => {
        return () => clearTimeout(timerRef.current);
    }, []);

    const reset = () => {
        clearTimeout(timerRef.current)
        timerRef.current = setTimeout(onFinish, time)
    };

    return { reset }
};
