import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { MessageWrapper } from './MessageWrapper';

import { LogoIcon, RetryIcon } from 'assets';

import styles from '../Chat.module.css';

export const Error = ({ retry, cancelled, children, showRetry = true, color = 'red.500' }) => {
  return (
    <MessageWrapper justifyContent="flex-start">
      <Box>
        {!cancelled && (
          <Box display="flex" alignItems="center" gap="8px">
            <Box w="20px" h="20px" flexShrink={0} display={['none', 'block']}>
              <LogoIcon />
            </Box>

            <Box
              display="flex"
              border="1px solid"
              borderColor={color}
              p="8px 12px"
              borderRadius="8px"
            >
              <Text color={color}>
                <div dangerouslySetInnerHTML={{ __html: children }}></div>
              </Text>
            </Box>
          </Box>
        )}
        {showRetry && <Box
          display="flex"
          alignItems="center"
          ml="28px"
          gap="8px"
          mt="8px"
          justifyContent="center"
          onClick={retry}
        >
          <Text fontSize="12px" color="#f56565" cursor="pointer">
            Regenerate response
          </Text>
          <RetryIcon className={styles.retry} />
        </Box>}
      </Box>
    </MessageWrapper>
  );
};
