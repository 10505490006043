import { FetchError } from 'utils/errors';

class Http {
  constructor(baseUrl) {
    if (!baseUrl) {
      throw new Error('No api url specified for build');
    }
    this.baseUrl = baseUrl;
    this.headers = {
      'Content-Type': 'application/json',
    };
  }

  fetch(method) {
    return (url, body, headers, signal = null) => {
      return fetch(`${this.baseUrl}/${url}`, {
        signal,
        method,
        headers: {
          ...this.headers,
          ...(headers ?? {}),
        },
        body,
      }).then(response =>
        response.json().then(result => {
          if (response.ok) {
            return result;
          }

          throw new FetchError(
            result.detail || result.message,
            response.status
          );
        })
      );
    };
  }

  setToken(token) {
    this.headers = {
      ...this.headers,
      Authorization: `Bearer ${token}`,
    };
  }

  post = this.fetch('POST');

  get = this.fetch('GET');

  put = this.fetch('PUT');

  delete = this.fetch('DELETE');

  patch = this.fetch('PATCH');
}

const REACT_APP_REST_API_URL = process.env.REACT_APP_REST_API_URL;
const REACT_APP_PREDICT_API_URL = process.env.REACT_APP_PREDICT_API_URL;
const REACT_APP_AGENT_API_URL = process.env.REACT_APP_AGENT_API_URL;

export const http = new Http(REACT_APP_REST_API_URL);
export const httpPredict = new Http(REACT_APP_PREDICT_API_URL);
export const httpAgent = new Http(REACT_APP_AGENT_API_URL);
