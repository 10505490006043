import { useState, useEffect } from 'react';

export const useQueryParams = param => {
  const [urlParameters, setUrlParameters] = useState(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const value = queryParams.get(param);
    setUrlParameters(value);
  }, [param]);

  return urlParameters;
};
