import { useRef, useState } from 'react';

import { useConstructor } from './useConstructor';

export const useAnswerAnimation = ({
  content: preprocessedContent,
  onFinish,
  sources,
  typingAnimation = true,
}) => {
  const content = preprocessedContent;
  const [typedContent, setTypedContent] = useState('');
  const play = useRef(true);
  // const [play, setPlay] = useState(true);

  const stopAnimation = () => {
    play.current = false;
    // setPlay(false);
  };

  useConstructor(() => {
    const typeContent = async () => {
      if (typingAnimation)
        for (let i = 0; i < content.length; i++) {
          if (!play.current) {
            onFinish();
            return;
          }
          await new Promise(resolve => setTimeout(resolve, 5));
          setTypedContent(prevContent => prevContent + content[i]);
        }
      else setTypedContent(content);

      onFinish();
    };

    typeContent();
  }, []);

  return {
    typedContent,
    stopAnimation,
  };
};
