import React, { forwardRef, useRef } from 'react';

import {
  Box,
  Icon,
  IconButton,
  InputGroup,
  InputRightElement,
  Spinner,
  Text,
  Textarea,
} from '@chakra-ui/react';
import { MdOutlineClose, MdHeadphones } from 'react-icons/md';
import { ResendIcon, UploadIcon } from 'assets';

import styles from './SendMessageInput.module.css';

export const SendMessageInput = forwardRef(
  (
    {
      onClick,
      onChange,
      colorButton,
      files,
      clearFiles,
      removeFile,
      handleFileChange,
      isFileUploadEnabled = false,
      ...props
    },
    ref
  ) => {
    const fileInputRef = useRef(null);

    return (
      <>
        {files.length > 0 && (
          <Box display="flex" justifyContent="center" gap={3} marginBottom={3}>
            {files.map((file, idx) => (
              <Box position="relative" key={idx}>
                <Box
                  style={{ opacity: file.status === 'loading' ? 0.3 : 1 }}
                  className={styles.fileWrapper}
                >
                  {file.status === 'loading' && (
                    <Spinner position="absolute" size="xl" thickness="2px" />
                  )}
                  {file.type === 'image' ? (
                    <img
                      src={file.src}
                      className={styles.fileWrapperImg}
                      alt={file.name}
                    />
                  ) : (
                    <>
                      <MdHeadphones size="32px" />
                      <Text
                        position="absolute"
                        bottom={1}
                        overflow="hidden"
                        width="80%"
                        fontSize="10px"
                        whiteSpace="nowrap"
                        textAlign="center"
                        textOverflow="ellipsis"
                      >
                        {file.name}
                      </Text>
                    </>
                  )}
                </Box>
                <IconButton
                  onClick={() => removeFile(file.id)}
                  size="xs"
                  icon={<MdOutlineClose />}
                  position="absolute"
                  top="3px"
                  right="3px"
                  borderRadius="50%"
                />
              </Box>
            ))}
          </Box>
        )}
        <InputGroup size="md">
          <Textarea
            pr="4.5rem"
            placeholder="Message"
            onChange={onChange}
            minH={0}
            resize="none"
            ref={ref}
            maxH={200}
            className={styles.textarea}
            style={{ backgroundColor: '#fff' }}
            {...props}
          />
          <input
            id="iptFile"
            type="file"
            accept="image/*, audio/*"
            ref={fileInputRef}
            onChange={handleFileChange}
            onClick={() => (document.getElementById('iptFile').value = null)}
            style={{ display: 'none' }}
            multiple
          />
          {isFileUploadEnabled && (
            <InputRightElement
              width="3.2rem"
              onClick={() => fileInputRef.current?.click()}
              cursor="pointer"
              top="50%"
              transform="translate(-70%, -50%)"
              className={styles.sendButton}
            >
              <UploadIcon {...(colorButton ? { fill: colorButton } : {})} />
            </InputRightElement>
          )}
          <InputRightElement
            width="3.2rem"
            onClick={onClick}
            cursor="pointer"
            top="50%"
            transform="translate(5%, -50%)"
            className={styles.sendButton}
          >
            <ResendIcon {...(colorButton ? { fill: colorButton } : {})} />
          </InputRightElement>
        </InputGroup>
      </>
    );
  }
);
