import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import { Login, Chat, Experiment } from 'pages';
import { withAuth } from 'HOC';

const routes = [
  { path: '/', Component: Login },
  { path: '/chat', Component: Chat },
  { path: '/experiment', Component: withAuth(Experiment) },
  { path: '*', Component: Login },
];

const router = createBrowserRouter(routes);

export default function Routes() {
  return <RouterProvider router={router} />;
}
