import React, { useEffect } from 'react';
import { useAuthenticationContext } from 'context';

export const withAuth = Component => {
  const ComponentWithAuth = props => {
    const { isAuthenticated, redirectToLogin } = useAuthenticationContext();

    useEffect(() => {
      if (isAuthenticated === false)
        redirectToLogin({ next: window.location.pathname });
    }, [isAuthenticated]);

    if (isAuthenticated === null) {
      return <p>Loading...</p>;
    }

    if (!isAuthenticated) {
      return null;
    }

    return <Component {...props} />;
  };

  return ComponentWithAuth;
};
