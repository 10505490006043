import React, { useState } from 'react';
import { Box, Button, Text } from '@chakra-ui/react';
import { TextField } from 'components';

export const Login = () => {
  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const onChangeHandler = ({ target: { value } }) => {
    setCode(value);
  };

  const onClickHandler = () => {
    setLoading(true);
    // TODO
    window.location.href = `/chat?idAgent=${code}`;
    setLoading(false);
  };

  return (
    <Box
      bgColor="white"
      p="24px"
      boxShadow="md"
      borderRadius="8px"
      maxW="500px"
      margin="auto"
    >
      <TextField
        label="Demo code"
        type="text"
        placeholder="Introduce your demo code"
        onChange={onChangeHandler}
      />
      <Button
        bgColor="brand.900"
        color="white"
        onClick={onClickHandler}
        width="100%"
        isLoading={loading}
      >
        Login
      </Button>

      {error && <Text color="red.500">{error}</Text>}
    </Box>
  );
};
