import { useState } from 'react';
import { getPresignedUrl } from 'services';
import { v4 as uuidv4 } from 'uuid';

const MAX_NUM_OF_FILES = 2;
// const dummyFiles = [
//   {
//     id: 1,
//     src: 'https://picwish.com/wp-content/uploads/2023/05/ai-chatbot-apps.jpg',
//     status: 'finished',
//     type: 'image',
//   },
//   {
//     id: 2,
//     name: 'dummy.mp3',
//     src: 'https://picwish.com/wp-content/uploads/2023/05/ai-chatbot-apps.jpg',
//     status: 'finished',
//     type: 'audio',
//   },
//   {
//     id: 3,
//     name: 'dumm yassa sadas dsad sads sadas dsada s.mp3',
//     src: 'https://picwish.com/wp-content/uploads/2023/05/ai-chatbot-apps.jpg',
//     status: 'loading',
//     type: 'audio',
//   },
//   {
//     id: 4,
//     name: 'dummyasdsadsadsadsadsadsadsadsadsadsadsadsadad.mp3',
//     src: 'https://picwish.com/wp-content/uploads/2023/05/ai-chatbot-apps.jpg',
//     status: 'finished',
//     type: 'audio',
//   },
// ];

export const useFileUpload = () => {
  const [files, setFiles] = useState([]);
  const loading = files.some(file => file.status === 'loading');

  const handleFileChange = async e => {
    let iptFiles = e.target.files;
    if (!iptFiles) return;
    if (iptFiles.length === 0) return;

    iptFiles = Array.from(iptFiles).splice(0, MAX_NUM_OF_FILES - files.length);

    for (let file of iptFiles) {
      const id = uuidv4();
      const fileType = file.type.startsWith('image/')
        ? 'image'
        : file.type.startsWith('audio/')
        ? 'audio'
        : '';
      const newFile = {
        id,
        name: file.name,
        src: URL.createObjectURL(file),
        status: 'loading',
        type: fileType,
      };
      setFiles(prev => [...prev, newFile]);

      uploadFile(
        file,
        url => {
          updateFile(id, 'status', 'success');
          updateFile(id, 'url', url);
        },
        error => {
          // updateFile(id, 'status', 'error');
          console.log('Error', error);
          removeFile(id);
        }
      );
    }
  };

  const updateFile = (id, key, value) => {
    setFiles(prev => prev.map(f => (f.id === id ? { ...f, [key]: value } : f)));
  };

  const clearFiles = () => {
    setFiles([]);
  };

  const removeFile = id => {
    setFiles(prev => prev.filter(file => file.id !== id));
  };

  const uploadFile = async (
    file,
    onUploadComplete,
    onFailed,
    useCogniflow = false
  ) => {
    try {
      if (useCogniflow) {
        const presignedUrl = await getPresignedUrl(uuidv4());
        if (!presignedUrl?.url) throw new Error('Something went wrong');

        handleFetch({
          url: presignedUrl.url,
          file,
          fields: presignedUrl.s3_fields,
          onProgress: ({ loaded, total }) => {
            // const currentProgress = Math.floor((loaded / total) * 100);
          },
          onUploadComplete: () => onUploadComplete(presignedUrl?.get_url),
          onFailed,
        });
      } else {
        const formData = new FormData();
        formData.append('file', file);
        const res = await fetch('https://tmpfiles.org/api/v1/upload', {
          method: 'POST',
          body: formData,
        });
        const data = await res.json();
        const url = new URL(data.data.url);
        url.pathname = `/dl${url.pathname}`;
        onUploadComplete(url.toString());
      }
    } catch (error) {
      onFailed();
    }
  };

  const handleFetch = ({
    url,
    file,
    fields,
    onProgress,
    onUploadComplete,
    onFailed,
  }) => {
    const ajax = new XMLHttpRequest();
    ajax.upload.onprogress = e => {
      onProgress(e);
    };
    ajax.upload.onerror = e => {
      onFailed(e);
    };
    ajax.upload.onabort = e => {
      onFailed(e);
    };
    ajax.onreadystatechange = () => {
      if (ajax.readyState === XMLHttpRequest.DONE) {
        if (ajax.status >= 200 && ajax.status < 300) {
          onUploadComplete();
        }
      }
    };

    ajax.open('POST', url);
    const data = new FormData();
    Object.keys(fields).forEach(key => {
      data.set(key, fields[key]);
    });
    data.set('file', file);
    data.set('success_action_status', '201');
    ajax.send(data);
  };

  return {
    loading,
    files,
    handleFileChange,
    clearFiles,
    removeFile,
  };
};
