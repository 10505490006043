import { httpPredict, httpAgent } from './http';

export const ask = ({
  question,
  signal,
  lang_code = null,
  idAgent = null,
  idModel = null,
  apiKey = null,
  idSession = null,
}) => {
  if (idModel) {
    return httpPredict.post(
      `text/question-answering/ask/${idModel}`,
      JSON.stringify({ question, id_session: idSession, lang_code }),
      {
        'x-api-key': apiKey,
      },
      signal
    );
  } else if (idAgent) {
    return httpAgent.post(
      `agent/${idAgent}/ask`,
      JSON.stringify({ query: question, session_id: idSession }),
      signal
    );
  }
};
