import { extendTheme } from '@chakra-ui/react';

export const theme = extendTheme({
  colors: {
    brand: {
      100: '#f7fafc',
      900: '#ff6a61',
    },
  },

  fonts: {
    body: 'Poppins',
    p: 'Poppins',
  },
});
