import React, {
  memo,
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { login as loginService, me } from 'services/auth';
import { http } from 'services/http';

const REACT_APP_FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL;

export const AuthenticationContext = createContext();

export const useAuthenticationContext = () => {
  return useContext(AuthenticationContext);
};

export const AuthenticationProvider = memo(({ children }) => {
  const [token, setToken] = useState();
  const [isAuthenticated, setAuthenticated] = useState(null);
  const [user, setUser] = useState(null);
  const userApiKey = useMemo(
    () => (user ? user.api_keys?.[0].key : ''),
    [user]
  );

  const getUserApiKey = () => localStorage.getItem('api_key')

  const loginSuccessfully = useCallback(token => {
    setAuthenticated(true);
    http.setToken(token);
    setToken(token);
  }, []);

  const authenticateUser = useCallback(
    ({ accessToken }) => {
      http.setToken(accessToken);
      localStorage.setItem('access_token', accessToken);
      loginSuccessfully(accessToken);
    },
    [loginSuccessfully]
  );

  const login = useCallback(
    (username, password) =>
      loginService({ username, password }).then(
        ({ access_token: accessToken }) => {
          authenticateUser({ email: username, accessToken });
        }
      ),
    [authenticateUser]
  );

  const logout = useCallback(() => {
    localStorage.removeItem('access_token');
    setAuthenticated(false);
  }, []);

  const fetchUser = useCallback(() => {
    me()
      .then(userData => {
        setUser(userData);
        localStorage.setItem('api_key', userData?.api_keys?.[0].key);
      })
      .catch(err => {
        setAuthenticated(false);
        console.error(err);
      });
  }, []);

  const redirectToLogin = useCallback(params => {
    let url = `${REACT_APP_FRONTEND_URL}/login?app=COGNIGPT`;
    if (params) {
      for (const [key, value] of Object.entries(params)) {
        url += `&${key}=${value}`;
      }
    }
    window.open(url, '_self');
  }, []);

  useEffect(() => {
    const accessToken = localStorage.getItem('access_token');
    if (accessToken) {
      loginSuccessfully(accessToken);
    } else {
      setAuthenticated(false);
    }
  }, [loginSuccessfully]);

  useEffect(() => {
    if (isAuthenticated) {
      fetchUser();
    }
  }, [isAuthenticated, fetchUser]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    if (token) {
      authenticateUser({ accessToken: token });

      // Remove query parameters by replacing the URL without the search string
      const currentUrl = window.location.href;
      const urlObj = new URL(currentUrl);
      urlObj.searchParams.delete('token');
      window.history.replaceState({}, document.title, urlObj.toString());
    }
  }, [authenticateUser]);

  return (
    <AuthenticationContext.Provider
      value={{
        login,
        logout,
        isAuthenticated,
        user,
        token,
        userApiKey,
        getUserApiKey,
        fetchUser,
        authenticateUser,
        redirectToLogin,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
});
