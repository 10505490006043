import { useRef, useEffect } from 'react';

export const useConstructor = callback => {
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      callback();
    }
  }, [callback]);
};
